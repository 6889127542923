import React from "react"
import Layout from "../components/layout"
import TermsPage from "../components/terms/terms-page.js"

const TermsConditions = () => {
  return (
    <Layout>
      <div className="contact_page termsPage">
        <div className="main-wrapper contact_page">
          <div className="main-content">
            <TermsPage />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TermsConditions
