import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const TermsPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulTermsAndConditions {
        title
        content {
          raw
        }
      }
    }
  `);
  const { title, content } = data.contentfulTermsAndConditions;
  return (
    <div className="termsWrapper">
      <h1>{title}</h1>
      <p>{renderRichText(content)}</p>
    </div>
  );
};

export default TermsPage;
